import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from '../pages/header/header.component';
import { FooterComponent } from '../pages/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSidenavModule, MatIconModule, MatTableModule, MatMenuModule, MatFormFieldModule, MatTabsModule, MatSelectModule, MatCardModule, MatTooltipModule, MatPaginatorModule, MatInputModule, MatButtonModule, MatCheckboxModule, MatAutocompleteModule, MatDatepickerModule, MatNativeDateModule, MatExpansionModule, 
   MatSnackBarModule, MatRadioModule, MatSortModule, MatOptionModule, MatSlideToggleModule, MatToolbarModule, MatChipsModule } from '@angular/material';
import { SentimentAnalysisComponent } from '../pages/sentiment-analysis/sentiment-analysis.component';
import { TextAnalysisComponent } from '../pages/text-analysis/text-analysis.component';
import { TextMiningComponent } from '../pages/text-mining/text-mining.component';
import { SapB1Component } from '../pages/sap-b1/sap-b1.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { LoginComponent } from '../pages/login/login.component';
import { SearchComponent } from '../pages/search/search.component';
import { SearchResultsComponent } from '../pages/search-results/search-results.component';
import { AnalysisComponent } from '../pages/analysis/analysis.component'
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from '../pages/home/home.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SentimentAnalysisComponent,
    TextAnalysisComponent,
    TextMiningComponent,
    SapB1Component,
    DashboardComponent,
    LoginComponent,
    SearchComponent,
    SearchResultsComponent,
    AnalysisComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    GaugeChartModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule, MatIconModule, MatTableModule, MatMenuModule, MatFormFieldModule, MatTabsModule,
     MatSelectModule, MatCardModule, MatTooltipModule, MatPaginatorModule, MatInputModule, MatButtonModule, 
     MatCheckboxModule, MatAutocompleteModule, MatDatepickerModule, MatNativeDateModule, MatExpansionModule,
       MatSnackBarModule, MatRadioModule, MatSortModule, MatOptionModule, MatSlideToggleModule, MatToolbarModule,
       MatChipsModule,
    NgxSliderModule,
    FormsModule,
    ReactiveFormsModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
