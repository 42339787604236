import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchText;

  constructor(public router: Router, public matSnackBar: MatSnackBar) { }

  ngOnInit() {
  }

  analyze(){
    if(this.searchText){
      this.router.navigate(['/dashboard/search-results'])
    }else{
      this.matSnackBar.open('Please enter Search Keyword','OK',{
        verticalPosition:'top',
        duration:2000,
        panelClass: 'snack-error'
      })
    }
  }

}
