import { Component, OnInit } from '@angular/core';
declare var Chart:any;


@Component({
  selector: 'app-sap-b1',
  templateUrl: './sap-b1.component.html',
  styleUrls: ['./sap-b1.component.scss']
})
export class SapB1Component implements OnInit {

  chart;

  constructor() { }

  ngOnInit() {
    this.DonutChart()
    this.barchartData()
  }

  DonutChart(){
    this.chart = new Chart('donutchart1', {
      type: 'doughnut',
      data: {
        labels: ["Positive", "Neutral", "Negative"],
        datasets: [{
            label: '# of Votes',
            data: [70, 10, 20],
            backgroundColor: [
                '#009fcf',
                '#ffc400',
                '#ff4b3a'
              
            ],
            borderColor: [
                '#f9f9fc',
            ],
            borderWidth:0
        }]
  
    },
    options: {
      cutoutPercentage: 80, 
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        },
        plugins: {
          labels: {
            render: 'label',
            position: 'outside',
            fontColor: '#fff',
          }
        },
        responsive: true,
    }
    });

    this.chart = new Chart('donutchart', {
      type: 'doughnut',
      data: {
        labels: ["Positive", "Neutral", "Negative"],
        datasets: [{
            label: '# of Votes',
            data: [70, 10, 20],
            backgroundColor: [
                '#009fcf',
                '#ffc400',
                '#ff4b3a'
              
            ],
            borderColor: [
                '#f9f9fc',
            ],
            borderWidth:0
        }]
  
    },
    options: {
      cutoutPercentage: 80, 
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        },
        plugins: {
          labels: {
            render: 'label',
            position: 'outside',
            fontColor: '#fff',
          }
        },
        responsive: true,
    }
    });

  }



  barchartData(){
    this.chart = new Chart('myCanvas1', {
      type: 'horizontalBar',
      data: {
        labels: ["US", "Brazil", "Japan", 'UK', 'Indonesia', 'India', 'Mexico', 'Spain', 'Canada'],
        datasets: [
            {
                backgroundColor: "#18b00f",
                data: [16342,12344,10568,9568,5698,2984, 1568, 958, 598],
                hoverBackgroundColor: '#18b00f'
            }
        ]
     
      
      },
      options:{
        legend: {
          display: false
        },
        borderRadius:10,
        barValueSpacing: 0,
        scales: {
          xAxes: [{
          
            ticks: {
              display:false
           },
            gridLines: {
              offsetGridLines: false,
              display:false
            },
           
        }],
            yAxes: [{
            
              barPercentage: 0.3,
                gridLines:{
                  offsetGridLines: false,
              display:false
                }
            }]
        }
      
      
      
  }
    });

    
  }

}
