import { Component, OnInit } from '@angular/core';
import { Options } from "@angular-slider/ngx-slider";
declare var Chart:any;

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit {
  value: number = 40;
  highValue: number = 60;
  options: Options = {
    floor: 0,
    ceil: 100,
    step: 10,
    showTicks: false
  };
  constructor() { }
  chart;

  ngOnInit() {
    this.getbarChart()
    this.donutChart()
  }

  getbarChart(){
    this.chart = new Chart('myCanvas', {
      type: 'bar',
      data: {
        labels: ["Negative", "Neutral", "Positive"],
        datasets: [
            {
                backgroundColor: [
                  "#e83b41",
                  "#3ab54a",
                  "#14c0f3"
                ],
                data: [75,2100,1000]
            }
        ]
     
      
      },
      options:{
        legend: {
          display: false
        },
      
        borderRadius:10,
        barValueSpacing: 0,
        scales: {
          xAxes: [{
            barPercentage: 0.75,
            gridLines: {
              offsetGridLines: false,
              display:false
            },
            ticks: {
          }
           
        }],
            yAxes: [{
            gridLines:{
              offsetGridLines: false,
              display:false
            },
            ticks: {
              display: false //this will remove only the label
          }
            }]
        }
      
      
      
  }
    });

    this.chart = new Chart('myCanvas2', {
      type: 'bar',
      data: {
        labels: ["9", "10", "11", '12', '13', '14'],
        datasets: [
            {
                label: "#8eb4f2",
                backgroundColor: "#fd9238",
                data: [3,7,4,5,4,6],
        borderRadius:10,

                hoverBackgroundColor:'#fd9238'
            },
            {
                label: "#d9a0f3",
                backgroundColor: "#fa168f",
                data: [2,2,2,2,2,2],
        borderRadius:10,

                hoverBackgroundColor:'#fa168f'
            }
          
        ]
     
      
      },
      options:{
        legend: {
          display: false
        },
        plugins: {
          labels: {
            render: 'label',
            position: 'outside',
            fontColor: '#fff',
          }
        },
        barValueSpacing: 0,
        scales: {
          xAxes: [{
            barPercentage: 0.2,
            stacked: true,
            gridLines: {
              offsetGridLines: true,
              display:false
            },
            ticks: {
              display:false
           }
        }],
            yAxes: [{
              stacked: true,
                ticks: {
                   display:false
                },
                gridLines:{
                  color:'#fdf9fa'
                }
            }]
        }
      
      
      
  }
    });

    this.chart = new Chart('myCanvas3', {
      type: 'bar',
      data: {
        labels: ["true", "Neutral", "Positive", "Positive", "Positive", "Positive", "Positive", "Positive", "Positive","Positive", "Positive", "Positive", "Positive", "Positive"],
        datasets: [
            {
                backgroundColor: '#fed794',
                data: [1500,1600,1450, 789,980,765,500,345, 667, 1500,1600,1450, 789,980]
            }
        ]
     
      
      },
      options:{
        legend: {
          display: false
        },
      
        borderRadius:10,
        barValueSpacing: 0,
        scales: {
          xAxes: [{
            barPercentage: 0.6,
            gridLines: {
              offsetGridLines: false,
              display:false
            },
            ticks: {
              display: false //this will remove only the label
          }
           
        }],
            yAxes: [{
            gridLines:{
              offsetGridLines: false,
              display:false
            },
            ticks: {
              display: false //this will remove only the label
          }
            }]
        }
      
      
      
  }
    });

  }

  donutChart(){
  
    this.chart = new Chart('myCanvas1', {
      type: 'doughnut',
      cutoutPercentage: 35,
      segmentShowStroke : false,
      percentageInnerCutout : 50,
   
      data: {
        datasets: [{
          data: [75,25],
          backgroundColor: ['#fb3b45', '#fdd2e6'],
          borderWidth: 7,
          borderRadius:30
        }, {
          data: [46,54 ],
          backgroundColor: ['#e3e6ed','#3ab54a' ],
          borderWidth: 7,
          borderRadius:30

        },
        {
          data: [54, 46],
          backgroundColor: ['#14c2f8', '#e3e6ed'],
          borderWidth: 7,
          borderRadius:30

        }],
        borderRadius:30
      },
      options: {
        rotation: -0.8 * Math.PI,
        legend: {
        display:false
        }
      }
    });

  }

}
