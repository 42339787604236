import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  selType='login';
  showPassword:boolean=false;
  showOtp:boolean=false;
  loginForm:FormGroup;
  constructor(public router: Router, public formBuilder: FormBuilder, public matSnackBar: MatSnackBar) { }

  send_otp(){
    this.showOtp = true;
  }

  verifyMobileOtp(){
    this.showPassword= true;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username:['', Validators.required],
      password:['', Validators.required]
    })
  }

  login(){
    if(this.loginForm.valid){
      if(this.loginForm.value.username == 'admin@adwaytech.com' && this.loginForm.value.password == 'adway@123'){
        this.router.navigate(['/dashboard'])
      }else{
        this.matSnackBar.open('Invalid credentials', 'OK', {
          verticalPosition:'top',
          duration:3000,
          panelClass:'snack-error'
        })
      }
    }else{
      this.matSnackBar.open('Please enter details to Login', 'OK', {
        verticalPosition:'top',
        duration:3000,
        panelClass:'snack-error'
      })
    }
  }

 

}
