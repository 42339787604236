import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnalysisComponent } from 'src/pages/analysis/analysis.component';
import { DashboardComponent } from 'src/pages/dashboard/dashboard.component';
import { HomeComponent } from 'src/pages/home/home.component';
import { LoginComponent } from 'src/pages/login/login.component';
import { SapB1Component } from 'src/pages/sap-b1/sap-b1.component';
import { SearchResultsComponent } from 'src/pages/search-results/search-results.component';
import { SearchComponent } from 'src/pages/search/search.component';
import { SentimentAnalysisComponent } from 'src/pages/sentiment-analysis/sentiment-analysis.component';
import { TextAnalysisComponent } from 'src/pages/text-analysis/text-analysis.component';
import { TextMiningComponent } from 'src/pages/text-mining/text-mining.component';



const routes : Routes = [
  {path:'',redirectTo:'login',pathMatch:'full'},
  {path:'login', component:LoginComponent},
  {path: 'dashboard', component:DashboardComponent, children:[

    {path:'',redirectTo:'home',pathMatch:'full'},
    {path:'home',component:HomeComponent},
    {path:'sentiment-analysis',component:SentimentAnalysisComponent},
    {path:'text-analysis',component:TextAnalysisComponent},
    {path:'text-mining',component:TextMiningComponent},
    {path:'sap-b1',component:SapB1Component},
    {path:'search',component:SearchComponent},
    {path:'search-results', component:SearchResultsComponent},
    {path:'analysis', component:AnalysisComponent}

  ]}
 
  ]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
