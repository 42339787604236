import { Component, OnInit } from '@angular/core';
declare var Chart:any;

@Component({
  selector: 'app-sentiment-analysis',
  templateUrl: './sentiment-analysis.component.html',
  styleUrls: ['./sentiment-analysis.component.scss']
})
export class SentimentAnalysisComponent implements OnInit {
chart;
public canvasWidth = 170
public needleValue = 60
public centralLabel = ''
public name = ''
public bottomLabel = ''
public options = {
    hasNeedle: true,
    needleColor: '#42494e',
    needleUpdateSpeed: 1000,
    arcColors: ['#ff4b3a', '#ffc400', '#009fcf'],
    arcDelimiters: [40,60],
    rangeLabel: ['1', '5'],
    needleStartValue: 50,
}
  constructor() { }

  ngOnInit() {
    this.DonutChart()
    this.pieChart()
    this.barchartData()
  }

  DonutChart(){
    this.chart = new Chart('donutchart', {
      type: 'doughnut',
      data: {
        labels: ["Share", "Like", "Reply", "Messages"],
        datasets: [{
            label: '# of Votes',
            data: [70, 30, 20, 50],
            backgroundColor: [
                '#fd6260',
                '#f2c811',
                '#00baac',
                '#374649',
            ],
            borderColor: [
                '#f9f9fc',
            ],
            borderWidth:0
        }]
  
    },
    showDatapoints: true,
    options: {
      cutoutPercentage: 60, 
        legend: {
            display: false
        },
        tooltips: {
          enabled: true
        },
        plugins: {
          labels: {
            // render: 'label',
            // position: 'outside',
            // fontSize: 10,
            // arc: true,
            // fontFamily: "'Google Sans",
          }
        },
        responsive: true,
    }
    });

    this.chart = new Chart('donutchart1', {
      type: 'doughnut',
      data: {
        labels: ["Positive", "Neutral", "Negative"],
        datasets: [{
            label: '# of Votes',
            data: [70, 10, 20],
            backgroundColor: [
                '#009fcf',
                '#ffc400',
                '#ff4b3a'
              
            ],
            borderColor: [
                '#f9f9fc',
            ],
            borderWidth:0
        }]
  
    },
    options: {
      cutoutPercentage: 80, 
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        },
        plugins: {
          labels: {
            render: 'label',
            position: 'outside',
            fontColor: '#fff',
          }
        },
        responsive: true,
    }
    });

  }

  pieChart(){
    this.chart = new Chart('piechart', {
      type: 'pie',
      data: {
        labels: ["Open Jobs", "No Submissions"],
        datasets: [{
            label: '# of Votes',
            data: [70, 30],
            backgroundColor: [
                '#80cce6',
                '#80e6ce',
               
            ],
            borderColor: [
                '#f9f9fc',
            ],
            borderWidth:0
        }]
  
    },
    options: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        },
       
        responsive: true,
    }
    });

    this.chart = new Chart('piechart1', {
      type: 'pie',
      data: {
        labels: ["Ned Hume", "Nettie Grant", "Darrel Doolittle", "Lois Gordone"],
        datasets: [{
            label: '# of Votes',
            data: [50, 30, 40, 35],
            backgroundColor: [
                '#ff9667',
                '#374649',
                '#ff6160',
                '#00b9a9',
                // '#616b6d'
            ],
            borderColor: [
                '#f9f9fc',
            ],

            borderWidth:0
        }]
  
    },
    options: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        },
         plugins: {
          labels: {
            render: 'label',
            position: 'outside',
            fontSize: 10,
            arc: true,
          }
        },
        responsive: true,
    }
    });
  }

  barchartData(){
    this.chart = new Chart('myCanvas1', {
      type: 'bar',
      data: {
        labels: ["9", "10", "11", '12', '13', '14'],
        datasets: [
            {
                label: "#8eb4f2",
                backgroundColor: "#009fcf",
                data: [3,7,4,5,4,6],
                hoverBackgroundColor:'#009fcf'
            },
            {
                label: "#d9a0f3",
                backgroundColor: "#ffc600",
                data: [2,2,2,2,2,2],
                hoverBackgroundColor:'#ffc600'
            },
            {
                label: "#d9a0f3",
                backgroundColor: "#ff4b3a",
                data: [2,2,2,2,2,2],
                hoverBackgroundColor:'#ff4b3a'
            }
        ]
     
      
      },
      options:{
        legend: {
          display: false
        },
        plugins: {
          labels: {
            render: 'label',
            position: 'outside',
            fontColor: '#fff',
          }
        },
        borderRadius:10,
        barValueSpacing: 0,
        scales: {
          xAxes: [{
            barPercentage: 0.5,
            stacked: true,
            gridLines: {
              offsetGridLines: true,
              display:false
            },
            ticks: {
              display:false
           }
        }],
            yAxes: [{
              stacked: true,
                ticks: {
                   display:false
                },
                gridLines:{
                  color:'#eceef8'
                }
            }]
        }
      
      
      
  }
    });
  }

}
