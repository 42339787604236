import { Component, OnInit } from '@angular/core';
declare var Chart:any;

@Component({
  selector: 'app-text-analysis',
  templateUrl: './text-analysis.component.html',
  styleUrls: ['./text-analysis.component.scss']
})
export class TextAnalysisComponent implements OnInit {
  chart;

  constructor() { }

  ngOnInit() {
    this.DonutChart()
    this.barchartData()
  }

  DonutChart(){
    this.chart = new Chart('donutchart1', {
      type: 'doughnut',
      data: {
        labels: ["Positive", "Neutral", "Negative"],
        datasets: [{
            label: '# of Votes',
            data: [70, 10, 20],
            backgroundColor: [
                '#009fcf',
                '#ffc400',
                '#ff4b3a'
              
            ],
            borderColor: [
                '#f9f9fc',
            ],
            borderWidth:0
        }]
  
    },
    options: {
      cutoutPercentage: 80, 
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        },
        plugins: {
          labels: {
            render: 'label',
            position: 'outside',
            fontColor: '#fff',
          }
        },
        responsive: true,
    }
    });

  }

  barchartData(){
    this.chart = new Chart('myCanvas1', {
      type: 'horizontalBar',
      data: {
        labels: ["US", "Brazil", "Japan", 'UK', 'Indonesia', 'India', 'Mexico', 'Spain', 'Canada'],
        datasets: [
            {
                backgroundColor: "#18b00f",
                data: [16342,12344,10568,9568,5698,2984, 1568, 958, 598],
                hoverBackgroundColor: '#18b00f'
            }
        ]
     
      
      },
      options:{
        legend: {
          display: false
        },
        borderRadius:10,
        barValueSpacing: 0,
        scales: {
          xAxes: [{
          
            ticks: {
              display:false
           },
            gridLines: {
              offsetGridLines: false,
              display:false
            },
           
        }],
            yAxes: [{
            
              barPercentage: 0.3,
                gridLines:{
                  offsetGridLines: false,
              display:false
                }
            }]
        }
      
      
      
  }
    });

    this.chart = new Chart('myCanvas', {
      type: 'bar',
      data: {
        labels: ["Twitter", "KFC", "Lego", 'Facebook', 'Dominos', 'Apple', 'Google', 'Uber'],
        datasets: [
            {
                backgroundColor: [
                  "#01b4dc",
                  "#9b67a5",
                  "#dc4335",
                  "#fab412",
                  "#8ea61c",
                  "#3589bc",
                  "#741481",
                  "#ca4504"
                ],
                data: [16342,12344,10568,9568,5698,2984, 1568, 958]
            }
        ]
     
      
      },
      options:{
        legend: {
          display: false
        },
      
        borderRadius:10,
        barValueSpacing: 0,
        scales: {
          xAxes: [{
            barPercentage: 0.75,
            gridLines: {
              offsetGridLines: false,
              display:false
            },
            ticks: {
              display: false //this will remove only the label
          }
           
        }],
            yAxes: [{
            gridLines:{
              offsetGridLines: false,
              display:false
            },
            ticks: {
              display: false //this will remove only the label
          }
            }]
        }
      
      
      
  }
    });
  }

}
